import { getAction,deleteAction,putAction,postAction} from '@/api/manage'

const queryPermissionsByUser = (params)=>getAction("/sys/permission/getUserPermissionByToken",params);
// 获取产品菜单
const productionList = (params) => getAction('/api/tax/sys/product/list',params);
// 获取公用菜单
const getPubFunAndItem = (params) => getAction('/api/tax/sys/productPubFun/getPubFunAndItem',params);
// 检查是否绑定微信
const checkBindWxMq = (params) => getAction('/api/tax/client/user/checkBindWxMq',params);
// 获取任务列表
const getRaskList = (params) => getAction('/slash-youth/client/b/task/list',params)
// 新建任务
const raskListAdd = (params) => postAction('/slash-youth/client/b/task/pushTask',params)
// 获取任务类型
const getRaskType = (params) => getAction('/api/tax/client/enterprise/trade/task/getTaskType2',params)
// 取消任务
const raskCancel = (params) => putAction(`/slash-youth/client/b/task/cancelTask/${params}`)
// 结束任务
const raskEnd = (params) => putAction(`/slash-youth/client/b/task/endApply/${params}`)
// 任务详情
const raskDetail = (params) => getAction(`/slash-youth/client/b/task/queryById`, params)
// 获取任务工作者
const getWorkList = (params) => getAction('/slash-youth/client/b/taskWorker/list',params)
// 签约
const raskAssign = (params) => postAction('/slash-youth/client/b/taskWorker/confirmApply',params)
// 拒绝签约
const refuseAssign = (params) => postAction('/slash-youth/client/b/taskWorker/refuseConfirmed',params)
// 取消签约
const cancelAssign = (params) => postAction('/slash-youth/client/b/taskWorker/cancelSign',params)
// 获取任务类别名称
const getRaskTypeName = (params) => getAction(`/api/tax/client/enterprise/trade/task/getTaskTypeById/${params}`)
// 获取预付款尾款列表
const taskWorkerPayItem = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/list',params)
// 获取统计预付款尾款
const settlementStatistical = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/settlementStatistical',params)
// 获取结算单详情
const getPayDetails = (params) => getAction('/slash-youth/client/b/taskWorkerPay/getPayDetails',params)
// 提交任务订单
const taskWorkerPaySubmit = (params) => postAction('/slash-youth/client/b/taskWorkerPay/submit',params)
// 获取任务审核列表
const getByWorkerIds = (params) => getAction(`/slash-youth/client/b/taskWorkerResult/queryPageList`,params)
// 审核任务
const taskWorkerResult = (params) => postAction('/slash-youth/client/b/taskWorkerResult/audit',params)

// 结算单
// 获取结算单列表
const getOrderList = (params) => getAction('/slash-youth/client/b/taskWorkerPay/list',params)
// 复核
const taskWorkerPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/review',params)
// 获取账户余额
const getBalance = (params) => getAction(`/api/tax/client/user/wallet/getWalletByType/${params}`)
// 预支付
const prePay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/prePay',params)
// 支付
const taskPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/pay',params)
// 获取发票列表
const ticketList = (params) => getAction('/slash-youth/client/b/task/confirmInvoiceList',params)
// 查询开票信息
const ticketInfos = (params) => getAction('/api/tax/client/invoice/getInvoiceTradesInfo',params)
// 查询收件人信息
const getInvoiceMailed = () => getAction('/api/tax/client/enterprise/receipt/getInvoiceMailed')
// 提交开票
const submitTicket = (params) => postAction('/slash-youth/client/b/task/confirmInvoice',params)
// 查看历史开票
const getApplyInvoiceTradeList = (params) => getAction('/api/tax/admin/enterprise/invoice/trade/getApplyInvoiceTradeList',params)
// 获取发票详情
const getApplyInvoiceTradeDetails = (params) => getAction(`/api/tax/admin/enterprise/invoice/trade/getApplyInvoiceTradeDetails/${params}`)
// 获取发票内容
const getInvoiceByIndustryId = (params) => getAction(`/api/tax/client/invoice/getInvoiceByIndustryId/${params}`)
// 获取邮箱
const getUserBindEmail = () => getAction('/api/tax/client/user/getUserBindEmail')
// 查询邀请人员
const getUserByPhone = (params) => getAction(`/api/tax/admin/user/getUserByPhone/${params}`)
// 邀请
const invitation = (params) => postAction('/slash-youth/client/b/taskWorker/invitation',params)
// 重置密碼
const resetPwd = (params) => postAction('/api/tax/client/user/payPassword/forget',params)
// 手机号发送短信
const sendInvitationSms = (params) => getAction(`/slash-youth/client/b/taskWorker/sendInvitationSms/${params}`)
// 推荐优质人才列表
const getRandomRecommendUser = () => getAction('/api/tax/admin/user/getRandomRecommendUser')
// 检验是否已报名
const checkRepetition = (params) => getAction('/slash-youth/client/b/taskWorker/checkRepetition',params)
const invitationRecommendUser = (params) => postAction('/slash-youth/client/b/taskWorker/invitationRecommendUser',params)
// 获取用户假数据
const getUserData = (params) => getAction('/api/client/sys/getUserData',params)
// getEnterpriseTradeDetaileds
const getEnterpriseTradeDetaileds = (params) => getAction('/api/tax/client/enterprise/trade/queryEnterpriseTradeDetaileds',params)
// 查询当前用户角色
const getRoleType = (params) => getAction('/api/tax/client/enterprise/trade/getRoleType',params)
// 批量邀请
const batchInvitationRecommendUser = (params) => postAction('/slash-youth/client/b/taskWorker/batchInvitationRecommendUser',params);
// 获取费率
const getServiceRule = (params) => getAction('/api/tax/client/enterprise/sign/getServiceRule',params);
// 获取费率
const getFeePoint = (params) => getAction('/api/tax/client/enterprise/sign/getFeePoint',params);
// 查询批量上传工作者列表
const queryImportWorkerList = (params) => getAction('/slash-youth/client/b/task/queryImportWorkerList',params)
// 提交批量上传工作者列表
const submitApply = (params) => postAction('/slash-youth/client/b/task/submitApply',params);
// 查询批量上传工作者列表汇总
const importWorkerStatistics = (params) => getAction(`/slash-youth/client/b/task/importWorkerStatistics/${params}`)
// 获取银行卡号
const getBanknum = (params) => getAction('/api/tax/client/user/wallet/recharge/getRechargeInfo/bank',params);
// 获取充值记录
const getRecharges = (params) => getAction('/api/tax/client/user/wallet/recharge/getRecharges2',params);
// 获取账户余额
const getRechargesAccount = (params) => getAction('/api/tax/client/user/wallet/getWalletByType/bank',params);
// 添加周期
const addPeriodPay = (params) => postAction('/slash-youth/client/b/taskWorkerPay/addPeriodPay',params);
// 添加周期
const taskWorkerPayItemList = (params) => getAction('/slash-youth/client/b/taskWorkerPayItem/list',params);
// 取消付款
const cancelPay = (params) => postAction(`/slash-youth/client/b/taskWorkerPayItem/cancelPay?id=${params}`);
// 提交结算
const submitPeriodPay = (params) => postAction(`/slash-youth/client/b/taskWorkerPay/submitPeriodPay`,params);
// 提交结算
const taskWorkerPayList = (params) => getAction(`/slash-youth/client/b/taskWorkerPay/list`,params);
// 确认申请发票
const confirmInvoice = (params) => postAction(`/slash-youth/client/b/taskWorkerPay/confirmInvoice`,params);
// 已开发票
const invoiceList = (params) => getAction(`/slash-youth/client/b/taskWorkerPay/invoiceList`,params);
// 关闭任务
const fulFilTask = (params) => postAction(`/slash-youth/client/b/task/fulFilTask/${params}`);
// 修改任务
const tastEdit = (params) => postAction('/slash-youth/client/b/task/edit',params);

export {
  getFeePoint,
  tastEdit,
  fulFilTask,
  invoiceList,
  confirmInvoice,
  taskWorkerPayList,
  submitPeriodPay,
  cancelPay,
  taskWorkerPayItemList,
  addPeriodPay,
  getRechargesAccount,
  getRecharges,
  getBanknum,
  importWorkerStatistics,
  submitApply,
  queryImportWorkerList,
  getServiceRule,
  batchInvitationRecommendUser,
  getRoleType,
  getEnterpriseTradeDetaileds,
  getUserData,
  invitationRecommendUser,
  checkRepetition,
  getRandomRecommendUser,
  sendInvitationSms,
  resetPwd,
  invitation,
  getUserByPhone,
  getUserBindEmail,
  getInvoiceByIndustryId,
  getApplyInvoiceTradeDetails,
  getApplyInvoiceTradeList,
  getInvoiceMailed,
  submitTicket,
  ticketInfos,
  ticketList,
  taskPay,
  prePay,
  getBalance,
  taskWorkerPay,
  getOrderList,
  taskWorkerResult,
  getByWorkerIds,
  taskWorkerPaySubmit,
  getPayDetails,
  settlementStatistical,
  taskWorkerPayItem,
  getRaskTypeName,
  cancelAssign,
  refuseAssign,
  raskAssign,
  getWorkList,
  getRaskList,
  raskListAdd,
  getRaskType,
  raskCancel,
  raskDetail,
  raskEnd,
  checkBindWxMq,
  getPubFunAndItem,
  productionList,
  queryPermissionsByUser,
}