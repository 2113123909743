import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录',
      slideMenu: false
    },
    component: () => import('../views/login/login')
  },
  {
    path: '/home',
    name: 'Login',
    meta: {
      title: '首页',
      slideMenu: false
    },
    component: Layout,
    children: [
      {
        path: '/home',
        meta: {
          title: '首页',
          slideMenu: false
        },
        component: () => import('../views/home/home')
      },
    ]
  },
  // 任务管理
  {
    path: '/raskManage',
    meta: {
      title: '任务管理',
      slideMenu: true
    },
    component: Layout,
    children: [
      {
        path: '/raskManage',
        meta: {
          title: '任务管理',
          slideMenu: true
        },
        component: () => import('../views/raskManage/rask')
      },
      {
        path: '/raskAdd',
        meta: {
          title: '新增任务',
          slideMenu: true
        },
        component: () => import('../views/raskManage/raskAdd')
      },
      {
        path: '/raskDetail',
        name: 'raskDetail',
        meta: {
          title: '新增详情',
          slideMenu: true
        },
        component: () => import('../views/raskManage/raskDetail')
      },
      {
        path: '/raskDetailMany',
        name: 'raskDetailMany',
        meta: {
          title: '新增详情',
          slideMenu: true
        },
        component: () => import('../views/raskManage/raskDetailMany')
      }
    ]
  },
  // 开票管理
  {
    path: '/kkfp',
    meta: {
      title: '开票管理'
    },
    component: Layout,
    children: [
      {
        path: '/kkfp',
        meta: {
          title: '开票管理',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticket')
      },
      {
        path: '/ticketDetail',
        meta: {
          title: '开票详情',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticketDetail')
      },
      {
        path: '/fpxx',
        meta: {
          title: '发票信息',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticketInfo')
      },
      {
        path: '/ticketSure',
        meta: {
          title: '确认发票',
          slideMenu: true
        },
        component: () => import('../views/ticketManage/ticketSure')
      }
    ]
  },
  // 结算管理
  {
    path: '/settlementManage',
    meta: {
      title: '付款管理'
    },
    component: Layout,
    children: [
      {
        path: '/settlementManage',
        meta: {
          title: '付款管理',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/settlement')
      },
      {
        path: '/settlementDetail',
        meta: {
          title: '付款详情',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/settlementDetail')
      },
      {
        path: '/rechargeManage',
        meta: {
          title: '充值管理',
          slideMenu: true
        },
        component: () => import('../views/settlementManage/rechargeManage')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
